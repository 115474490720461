import { css } from "@emotion/css";
import { Link, Outlet } from "react-router-dom";
import useAuth from "../hooks/AuthHook";

const menuItems = [
  { name: "Home", link: "/", isPrivate: false },
  { name: "Logout", link: "/logout", isPrivate: true },
  { name: "Login", link: "/login", isPrivate: false },
  { name: "Signup", link: "/signup", isPrivate: false },
];

const Navbar = () => {
  const { isLoggedIn } = useAuth();

  return (
    <>
      <nav>
        <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 1rem;
            padding: 1rem;
          `}
        >
          {menuItems.filter((item) => item.isPrivate === isLoggedIn)
          .map((item) => (
            <div key={item.name}>
              <Link
                className={css`
                  text-decoration: none;
                  color: black;
                  font-size: 1.2rem;
                  font-weight: bold;
                  text-transform: uppercase;
                  &:hover {
                    color: #f1356d;
                  }
                `}
                to={item.link}
              >
                {item.name}
              </Link>
            </div>
          ))}
        </div>
        {/* <div className="menu-icon" onClick={toggleMobileMenu}>
          &#9776;
        </div> */}
      </nav>
      <Outlet />
    </>
  );
};

export default Navbar;
