import './index.css';
import useAuth from './hooks/AuthHook';
import RootRouter from './routes/rootRouter';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './components/ScrollToTop';
import SpinnerWrapper from './components/SpinnerWrapper';

const App = () => {
  const { isLoading } = useAuth();

  if (isLoading) return <SpinnerWrapper />;

  return (
    <ScrollToTop>
      <RootRouter />
    </ScrollToTop>
  );
};

reportWebVitals();

export default App;