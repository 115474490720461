import { lazy } from "react";
import Loadable from "./loadable";
import Navbar from "../layout/Navbar";

const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const SignUp = Loadable(lazy(() => import("../pages/auth/Signup")));
const Logout = Loadable(lazy(() => import("../pages/auth/Logout")));
const Create = Loadable(lazy(() => import("../pages/create")));

const GeneralRoutes = {
  path: "/",
  element: <Navbar />,
  children: [
    {
      path: "/",
      element: <Create />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
   
    //   path: "/*",
    //   element: <PageNotFound />,
    // },
  ],
};

export default GeneralRoutes;
